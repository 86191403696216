
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
a {
    text-decoration: none !important;
}
@media (min-width: 769px){
    .invertoryslider h4{
        font-size: 25px;
    font-weight: 400;
    line-height: 16px;
    font-style: normal;
    color: #ffffff;
    padding-top: 15px;
    }
    .invertoryslider img{
        width: 150px;
    }
    .marketplaceslider {
        background-image: url("https://images.pexels.com/photos/9399540/pexels-photo-9399540.jpeg?auto=compress&cs=tinysrgb&w=600");
        /* background-image: url(/public/images/Rectangle\ \(2\).png); */
        height: 350px !important;
       width: 100%;
       background-size: cover;
       margin-top: 20px;
       background-position: center;
       /* display: flex; */
       align-items: end;
    }
}
@media (max-width: 768px){
    .profileheading{
        font-size: 17px;
    font-weight: 400;
    line-height: 16px;
    font-style: normal;
    text-align: left;
    color: #575962;
    }
    
    .main_profile{
        height: 100px !important;
        width: 100%;
        padding: 25px 10px !important;
    }
    .main_profile h3 {
        font-size: 16px !important;
        padding-top: 40px;
    }
    .invertoryslider h4{
        font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    font-style: normal;
    color: #ffffff;
    padding-top: 15px;
    }
    .marketplaceslider {
        background-image: url("https://images.pexels.com/photos/9399540/pexels-photo-9399540.jpeg?auto=compress&cs=tinysrgb&w=600");
        /* background-image: url(/public/images/Rectangle\ \(2\).png); */
        height: auto !important;
       width: 100%;
       background-size: cover;
       margin-top: 20px;
       background-position: center;
       /* display: flex; */
       align-items: end;
    }
}
.singleslide img{
    border-radius: 10px !important;
}
.userdetails p{
    font-size: 13px;
font-weight: 400;
line-height: 16px;
font-style: normal;
text-align: left;
color: #ffffff !important;
}
.userdetails{
    height: 350px;
    display: flex;
    align-items: end;
    padding-bottom: 50px;
}
.userdetails img{
    width: 50px !important;
}
.gamesslider .games{
    font-size: 13px;
font-weight: 400;
line-height: 16px;
font-style: normal;
/* text-align: center; */
color: #575962 !important;
background: #0e0f11;
padding: 5px;
border-radius: 5px;
}
.profileheading{
    font-size: 35px;
font-weight: 400;
line-height: 16px;
font-style: normal;
text-align: left;
color: #575962;
}
.Cardtitles{
    font-size: 35px;
    font-weight: 500;
    line-height: 20px;
    font-style: normal;
    text-align: left;
    color: #ffffff !important;
}
@media(max-width: 580px){
    .Cardtitles{
        font-size: 24px !important;
    }
}
.dashborder{
    border: 2px #202125 dashed;
    height: 100%;
    width: 100%;
    background-color: #0E0F11;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
}
.dashborder input{
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    display: inline;
    background: transparent;
    width: 100%;
    height: 107%;
    font-size: 0;
    z-index: 1111111;
}
.plusicon{
    position: absolute;
    font-size: 25px;
}
.inventory h3{
    font-size: 40px;
font-weight: bold;
line-height: 32px;
font-style: normal;
text-align: left;
color: #ffffff;
}
.uploadfiles p{
    font-size: 30px;
font-weight: 600;
line-height: 16px;
font-style: normal;
text-align: left;
color: #575962;
padding: 20px 0px;
display: inline;
}
.uploadfiles a{
    font-size: 30px;
    font-weight: 600;
    line-height: 16px;
    font-style: normal;
    color: #007fff;
}

@media (max-width: 768px){
    .inventory h3{
        font-size: 30px;
    }
    .uploadfiles p{
        font-size: 17px;
    font-weight: 400;
    padding: 10px 0px;
    }
    .uploadfiles a{
        font-size: 17px;
        font-weight: 400;
        line-height: 16px;
        font-style: normal;
        color: #007fff !important;
    }
    .collectionslieders img{
        width: 66px !important;
    }
    .singleslide img{
        width: 100% !important;
        height: 100px !important;
        
    }
}
.bobs_collection{
    
    /* background-image: url(/public/images/Rectangle.png); */
    height: 200px !important;
   /* width: 100%; */
   border-radius: 10px;
   background-size: cover;
   margin-top: 20px;
   background-position: center;
   display: flex;
   align-items: end;
   margin-top: 25px;
}
.singleslide img{
    width: 100% !important;
    height: 300px;

}
input:-webkit-autofill {
    background-color: transparent !important;
  }