
@media (min-width: 769px){
    .bgbanner{
        background-image: linear-gradient( rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(/public/images/football.png); 
        /* background-image: url(); */
        height: 300px;
        width: 100%;
        background-size: cover;
        background-position: center;
        padding-top: 25px;
        background-repeat: no-repeat;
    }
}
@media (max-width: 768px){
    .bgbanner{
        background-image: linear-gradient( rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(/public/images/Mask.png); 
        padding: 0px 15px;
        /* background-image: url(); */
        height: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        background-size: cover;
        background-position: center;
        padding-top: 0px;
        border-radius: 14px;
        background-repeat: no-repeat;
    }
    select {
        font-size: 17px !important;

    }
    .backgroundcolor {
        padding: 0px 15px;
    }
    .whereto h2{
        font-size: 24px !important;
        line-height: 30px !important;
    }
    .bgbanner h2{
        /* font-family: "sf pro text"; */
        font-size: 24px !important;
        font-weight: 400;
        line-height: 30px !important;
        text-align: left !important;
    }
    .main_profile{
        height: 100px !important;
        width: 100%;
        padding: 25px 10px !important;
    }
    .main_profile h3 {
        font-size: 16px !important;
        padding-top: 40px;
    }
}
.bgbanner h2{
    /* font-family: "sf pro text"; */
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    font-style: normal;
    color: #ffffff;
    text-align: center;
}
.bgbanner span{
    /* font-family: "sf pro text"; */
    font-weight: 600;
    font-size: 20px;
    font-style: normal;
    color: #ffffff;

}
.backgroundcolor{
    background-color: #101010;
}
.whereto h2{
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    font-style: normal;
    color: #ffffff;
    padding: 25px 0px;
}
.main_profile{
    /* background-image: linear-gradient( rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(/public/images/Background\ \(1\).png);  */
    background-image: url(/public/images/Background\ \(1\).png);
    height: 300px;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 14px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 25px;
   
}
.main_profile h3{
    font-size: 35px;
font-weight: 500;
line-height: 20px;
font-style: normal;
text-align: left;
color: #ffffff;


}
/* .carditem{
    background-image: url(/public/images/Background\ \(1\).png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
} */
.Cardtitle{
    font-size: 35px;
    font-weight: 500;
    line-height: 20px;
    font-style: normal;
    text-align: left;
    color: #ffffff;
}
@media(max-width: 580px){
    .Cardtitle{
        font-size: 24px !important;
    }
}

select{
    background-color: #101010;
    border: none;
    color: white;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 2px;
}
select:focus{
    outline: none;
}