.Wrapper1 .signinbutton {
  width: 100%;
  background-color: #007fff;
  padding: 20px;
  color: white !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  border: none;
  /* margin-top: 30px; */
  border-radius: 10px;
}
.login_Background1 {
  background-color: #191a1e;
  /* height: 100vh; */
  padding-top: 0px;
}
.Wrapper1 {
  height: 100vh;
}
.Wrapper1 h1 {
  color: white;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 38px !important;
  text-align: center;
}
.Wrapper1 img {
  width: 120px;
  padding-bottom: 25px;
}
.Wrapper1 input {
  width: 100%;
  background-color: #0e0f11;
  padding: 20px;
  color: white !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  border: none;
  margin-top: 30px;
  border-radius: 10px;
}

.Wrapper1 span {
  color: #45474f;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px;
}

@media (max-width: 768px) {
  .twitteruser i {
    color: #007fff;
    font-size: 20px;
    padding-top: 6px;
  }
  input[type="text"] {
    font-size: 15px !important;
  }
  .login_Background {
    padding-top: 20px;
  }
  .Wrapper span {
    font-size: 15px !important;
  }
  .Wrapper input {
    font-size: 15px !important;
    /* padding: 0px !important; */
    /* padding-left: 20px !important; */
    height: 44px !important;
    /* margin-top: 15px !important; */
  }
  .Wrapper .signinbutton {
    font-size: 15px !important;
    padding: 0px !important;
    height: 44px !important;
  }
  button.guest.continue {
    font-size: 15px !important;
    padding: 0px !important;
    height: 44px !important;
    margin-top: 15px !important;
  }
  .Wrapper .twitteruser {
    padding: 0px !important;
    height: 44px !important;
    padding-left: 10px !important;
    margin-top: 15px !important;
  }
  .twitteruser span {
    font-size: 15px !important;
  }
  .Wrapper img {
    width: 60px !important;
    padding-bottom: 5px;
  }
  .Wrapper h1 {
    color: white;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 21px !important;
    text-align: center;
  }

  input[type="password"] {
    margin-top: 0px !important;
  }
}

.anticon svg {
  color: #565252 !important;
}
.ant-input {
  margin-top: 0px !important;
  padding: 20px !important;
  border-radius: 10px !important;
}
.ant-input-password {
  padding: 0px !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  background-color: #0e0f11 !important;
  border: none !important;
}
.ant-input-suffix {
  margin: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
